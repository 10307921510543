import React, { useRef, useEffect } from 'react';
import { MapComponent } from '../components/Map';

import shp from "shpjs";
import L from "leaflet";
import { MapNav, MapOuterDiv, MapNavbarContainer, MapNavLogo,MapNavDropdownItem} from '../components/Map/MapElements';
import { Navigate, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import AnalysisPanel from '../components/AnalysisPanel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import authService from '../utils/AuthenticationService';
import { getUserAssets, getAssetLayerData, getClimateRegistry, getClimateLayerDisplayInfo, getRiskScores, getClientConfig, getGeoserverProxy } from '../utils/apiRequestBuilder';
import Fab from '@mui/material/Fab';
import ClimateLayerMenu from '../components/ClimateLayerMenu';
import Typography from '@mui/material/Typography';


import { SettingsDropdown } from '../components/Settings/settingsDropdown';
import { DataGrid, GridToolbarFilterButton, GridToolbarContainer, GridToolbarExport, GridColumnGroupingModel, useGridApiContext, gridFilteredSortedRowIdsSelector, getGridNumericOperators, getGridStringOperators  } from '@mui/x-data-grid';
import DetailsModal from '../components/DetailsModal';
import RiskLegend from '../components/RiskLegend';
import ClimateLayerLegend from '../components/ClimateLayerLegend';
import Radio from '@mui/material/Radio';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { generateSummaryReport } from '../utils/summaryReportGenerator';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const loaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: 'rgba(0,0,0,0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px 20px 30px'
};

const loadingDescriptionStyle = {
    fontSize: '1.2rem',
    color: 'white',
    marginTop: '10px'
};

const menu_button = {
    background: '#FFF',
    borderRadius: '6px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)',
    border: '1px solid #FFF',
    fontSize: '9pt',
    color: '#000',
    transition: '.4s'
};

const custom_menu_dropdown = {
    padding: '5px 10px 5px',
    width: 'fit-content',
    position: 'fixed',
    top: '50px'
}

const menu_button_container = {
    display: 'flex',
    flexDirection: 'column'
}

const menu_dropdown_options = {
    '.MuiFormControlLabel-label': {
        fontSize: '10pt'
    },
    '.MuiSvgIcon-root': {
        fontSize: '10pt'
    }
}

const mapAnalysisToggleButton = {
    color: '#fff',
    bgcolor: '#000',
    '&:hover': {
      bgcolor: 'rgba(33,33,33,1)',
    }
}

const data_grid_styles ={
    opacity:0.9,
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {width: 10, height: 10},
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {background: '#f1f1f1'},
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {backgroundColor: '#888'},
    '& .MuiTablePagination-displayedRows':{marginBottom:0},
    "& .MuiDataGrid-columnHeaders":{backgroundColor:'#f6fafd', fontWeight:'bold', whiteSpace: 'normal !important' },
    "& .MuiDataGrid-columnHeaderTitle":{fontWeight:'bold', overflow: "visible", whiteSpace: 'normal !important' },
    "& .MuiDataGrid-columnHeaderTitleContainer":{justifyContent:'center', whiteSpace: 'normal !important', textAlign: 'center', lineHeight: "1rem" },
    "& .MuiDataGrid-cell":{ justifyContent:'center' } 
}

const riskTableStyles = {
    height: 506,
    width:'99vw',
    opacity: 0.97,
    '& .risk_green': {
        backgroundColor: 'rgba(168, 208, 141, .5)',
    },
    '& .risk_blue': {
        backgroundColor: 'rgba(0, 176, 240, .5)',
    },
    '& .risk_5': {
        backgroundColor: 'rgba(255, 230, 153, .5)',
    },
    '& .risk_yellow': {
        backgroundColor: 'rgba(255, 255, 0, .5)',
    },
    '& .risk_orange': {
        backgroundColor: 'rgba(255, 119, 0, .5)',
    },
    '& .risk_red': {
        backgroundColor: 'rgba(255, 0, 0, .5)',
    },
    '& .row_header':{
        backgroundColor: '#f6fafd'
    },
}

const assetLegendContainer = {
    position: 'absolute',
    bottom: '45%',
    right: '10px',
    zIndex: '1000',
    color: '#233845',
    fontSize: '12px',
    fontWeight: 700
}

const assetLegendItems = {
    background: 'rgba(0, 0, 0, 0.5)',
    paddingRight: '10px',
    textAlign: 'center',
    width: '250px',
    height: 'fit-content',
    flexDirection: 'column',
    display: 'flex'
}

const assetLegendRow = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    marginBottom: '5px'
}

const assetLegend_row_subtitle = {
    color: "#fff",
    marginLeft: '10px'
}

const tab_panel_container = {
    padding: '0px'
}

const assetRandomShapes = ['circle', 'rectangle', 'triangle']; //to be used in the future to create variations when there is multiple assets on the map

const MapPage = () => {
    
    const navigate = useNavigate();
    const mapCompRef = useRef();
    const [mapLayers, updateMapLayers] = React.useState([]);
    const [analysisPanelOpen, toggleAnalysisPanel] = React.useState(false);
    const [isLoading, updateLoading] = React.useState(true);
    const [loadingDescription, updateLoadingDescription] = React.useState('Loading..');
    const [clientConfig, updateClientConfig] = React.useState(null);
    const [assetLayers, updateAssetLayers] = React.useState([]);
    const [assetLayerMenuStatus, updateAssetLayerMenuStatus] = React.useState(false);
    const [climateLayerMenuStatus, updateClimateLayerMenuStatus] = React.useState(false);
    const [climateLayerTrees, updateClimateLayerTree] = React.useState([]);
    const [climateRegistry, updateClimateRegistry] = React.useState([]);
    const [settingsMenuStatus, updateSettingsMenuStatus] = React.useState(false);

    const [analysisResults, updateAnalysisResults] = React.useState([]);
    const [showAnalysisResultsTab, updateShowAnalysisResultsTab] = React.useState(false);
    const [allAnalysisRiskData, updateAnalysisRiskData] = React.useState([]);
    const [analysisFilter, updateAnalysisFilter] = React.useState([]);
    const [currentAnalysisTab, updateCurrentAnalysisTab] = React.useState('0');

    //detailed risk analysis states
    const [detailedRiskModelOpen, toggleDetailedRiskModal] = React.useState(false);
    const [detailRiskModalAsset, updateDetailRiskModalAsset] = React.useState(null);


    const [assetsOnMap, refreshAssetsOnMap] = React.useState([]);
    const [assetLegend, updateAssetLegend] = React.useState([]);
    const [climateLayerOnMap, updateMapClimateLayers] = React.useState(null);

    const [selectedAssetLayers, updateSelectedAssets] = React.useState([])
    const [selectedClimateScenario, updateSelectedClimateScenario] = React.useState([])
    const [selectedTimeframe, updateSelectedTimeframe] = React.useState([])
    const [dataSources, updateDataSource] =  React.useState([])
    const [sourceChildren, updateSourceChildren] = React.useState([])

    // States for climate layer menu
    const [menuClimateScenario, setMenuClimateScenario] = React.useState('RCP4.5/SSP2-4.5');
    const [menuClimateTimeframe, setMenuClimateTimeframe] = React.useState('Historical');
    const [menuClimateParameter, setMenuClimateParameter] = React.useState('none');

    const toggleClimateLayerMenu = () => {
        if (assetLayerMenuStatus) {
            updateAssetLayerMenuStatus(false);
        }
        updateClimateLayerMenuStatus(!climateLayerMenuStatus);
    }
    const toggleAssetLayerMenu = () => {
        if (climateLayerMenuStatus) {
            updateClimateLayerMenuStatus(false);
        }
        updateAssetLayerMenuStatus(!assetLayerMenuStatus);
    }
    const toggleSettingsMenu = () => updateSettingsMenuStatus(!settingsMenuStatus);

    const handleBackdropClick = (event, reason) => {
        if (reason && reason == "backdropClick"){
            return
        }
    }

    const closeAnalysisPanel = () => toggleAnalysisPanel(false);
    const openAnalysisPanel = () => { 
        updateClimateLayerMenuStatus(false);
        updateAssetLayerMenuStatus(false);
        toggleAnalysisPanel(true);
    }
    const openAnalysisResultsTab = () => updateShowAnalysisResultsTab(true);
    const closeAnalysisResultsTab = () => updateShowAnalysisResultsTab(false);

    const openDetailedRiskModal = (asset, definition, layerId) => {
        const assetLayerInfoIndex = assetLayers.findIndex((layers) => layers.id == layerId);
        const assetLayerInfo = assetLayerInfoIndex == -1 ? {} : assetLayers[assetLayerInfoIndex];
        updateDetailRiskModalAsset({'metadata': asset, 'layer_id': layerId, 'definition': definition, 'asset_layer_info': assetLayerInfo});
        toggleDetailedRiskModal(true);
    }

    const closeDetailedRiskModal = () => {
        updateDetailRiskModalAsset(null);
        toggleDetailedRiskModal(false);
    }

    const updateClimateLayerFilter = (timeframe, scenario, climateLayerId) => {

        setMenuClimateTimeframe(timeframe);
        setMenuClimateScenario(scenario);
        setMenuClimateParameter(climateLayerId);

        // updating map layer
        updateLayersOnMap(climateLayerId);
    }
    


    function formatStringValue(value) {
        if (typeof value === 'string') {
            const camelCase = value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/[_]/g, ' ');
            const formatedValue = camelCase.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
            return formatedValue;
        } else {
            return value;
        }

    }

    function filterRiskDataOnMap(layer, assets, assetDefinition, assetLayerId) {


        let currentRiskFilters = [...analysisFilter];
        mapCompRef.current.mapRef().closePopup(); // closing all popups on the map to prevent assets from being highlighted and displaying the incorrect tooltip info
        const revelantAnalysisFilterIndex = currentRiskFilters.findIndex((filter) => filter.asset_layer_id == assetLayerId);
        if (revelantAnalysisFilterIndex != -1) {
            currentRiskFilters.splice(revelantAnalysisFilterIndex, 1);
        } 
        
        if (layer != 'max_risk') {
            currentRiskFilters.push({filter: layer, asset_layer_id: assetLayerId });
        }


        updateAnalysisFilter(currentRiskFilters);

        const currentMapLayers = mapCompRef.current.mapRef()._layers;

        assets.forEach((asset) => {
            const assetMarkerKey = Object.keys(currentMapLayers).find((layerKey) => currentMapLayers[layerKey]?.feature?.properties.id == asset.id && currentMapLayers[layerKey]?.feature?.asset_layer_id == assetLayerId);
            if (assetMarkerKey) {
                const layersToCompare = layer == 'max_risk' ? Object.keys(asset).filter((assetProperty) => !sortAssetMetadataKeys(assetDefinition).includes(assetProperty) && assetProperty != 'row_id') : Object.keys(asset).filter((assetProperty) => { return assetProperty == layer; }); //getting asset properties which has risk data we want to filter by
                const maxRisk = Math.max(...layersToCompare.map(layerProperty => parseFloat(asset[layerProperty]) ));
                const riskColor = getRiskColor(maxRisk);
                currentMapLayers[assetMarkerKey].setStyle({fillColor: riskColor });

                // updating tooltip content
                const tooltipContent = getAssetTooltipContent(asset, assetDefinition, assetLayerId, null, layer);
                currentMapLayers[assetMarkerKey].setTooltipContent(tooltipContent.join("<br />"));
            }

        });
    }

    function isAnalysisFilterActive(header, assetLayerId) {
        const currentRiskFilters = [...analysisFilter];
        const relevantAssetFilterIndex = currentRiskFilters.findIndex((filter) => filter.asset_layer_id == assetLayerId);

        if (relevantAssetFilterIndex == -1 && header == "max_risk") {
            return true;
        }

        if(relevantAssetFilterIndex != -1) {
            if (header == currentRiskFilters[relevantAssetFilterIndex].filter) {
                return true;
            }
        }

        

        return false;
    }

    function determineColumnWidth(header, isRiskColumn, numberOfColumns) {

        if (numberOfColumns <= 14) {
            return isRiskColumn ? header.length * 7.5 : header.length * 8; 
        } else {
            return header.length * 5.7; 
        }
    }

    const parseAssetGridHeader = (data, definition, assetLayerId) => {
        const headerKeys = Object.keys(data[0]);
        const sortedDefinitionDiffKeys = sortAssetMetadataKeys(definition).filter(x => headerKeys.includes(x)); // getting asset property column names according to asset definition in database

        const extraDataKeys = headerKeys.filter(key => !sortedDefinitionDiffKeys.includes(key)); // asset property columns that are not defined in asset table definition
        const sortedExtraDataKeys = extraDataKeys.filter(str => str != 'max_risk' && str != 'row_id').sort(); // sorting values except max_risk and row_id value since that property needs to be the last item
        sortedExtraDataKeys.unshift('max_risk');
        sortedExtraDataKeys.push('row_id');

        const sortedHeaderKeys = sortedDefinitionDiffKeys.concat(sortedExtraDataKeys);


        const nonSuppressibleKeys = sortedHeaderKeys.filter((key) => { // getting asset properties that are supposed to be shown
            const definitionIndex = definition.findIndex((property) => property.column_name == key);
            return definitionIndex == -1 || !definition[definitionIndex].is_suppressible;
        });

        const numberOfColumns = nonSuppressibleKeys.length;

        const headers = nonSuppressibleKeys.map((header, index) => {
            const definitionIndex = definition.findIndex((property) => property.column_name == header);
            const formatedHeaderName = definitionIndex == -1 ? formatStringValue(header) : formatStringValue(definition[definitionIndex].column_alias);
            if (!sortAssetMetadataKeys(definition).includes(header)) { // we only want radio button for columns representing climate layer risk data
                return { 
                    field: header,
                    minWidth: 120,
                    width: determineColumnWidth(formatedHeaderName, true, numberOfColumns),
                    sortable: false,
                    headerName: formatedHeaderName,
                    renderHeader: () => (
                        <>
                          {formatedHeaderName}
                          {extraDataKeys.length < 2 ? null : <Radio checked={isAnalysisFilterActive(header, assetLayerId)} onChange={() => filterRiskDataOnMap(header, data, definition, assetLayerId)} /> }
                        </>
                    )
                }
            } else {
                return { field: header, headerName: formatedHeaderName, minWidth: 110, width: determineColumnWidth(formatedHeaderName, false, nonSuppressibleKeys.length)}
            }
        });

        const detailedRiskHeader = { // creating column so that user can view risk details
            field: 'detailed_risk',
            headerName: 'Risk Details',
            width: 110,
            renderCell: (params) => {
                const openDetails = (e) => {
                    e.stopPropagation();
                    closeAnalysisResultsTab();
                    openDetailedRiskModal(params.row, definition, assetLayerId);

                }
                return <Button onClick={openDetails}>Details</Button>
            }
        }
        const assetMetadataKeys = sortedDefinitionDiffKeys.filter((key) => { // getting all assetMeta properties that will show in the analysis table
            const definitionIndex = definition.findIndex((property) => property.column_name == key);
            return !definition[definitionIndex].is_suppressible;
        });
        const detailsIndex = assetMetadataKeys.length;// getting last index of asset metadata to insert risk details header

        headers.splice(detailsIndex, 0, detailedRiskHeader);
        return headers;
    }

    function getRiskCellClass(params) {

        const sourceIndex = sourceChildren.findIndex((child) => child.field == params.field);

        if ( sourceIndex != -1 || params.field == "max_risk") { // we only want to add color to columns with risk information
            const risk = parseFloat(params.value);
            if (risk >= 0 && risk < 3) {
                return 'risk_green';
            } else if (risk >= 3 && risk < 5) {
                return 'risk_blue';
            } else if (risk >= 5 && risk < 6) { // want to cater for decimal values like 5.5, 5.6 etc
                return 'risk_5';
            } else if (risk >=6 && risk < 10) {
                return 'risk_yellow';
            } else if (risk >= 10 && risk < 17) {
                return "risk_orange";
            } else if (risk >= 20) {
                return "risk_red";
            }
        } else if(params.field == 'property'){
            return "row_header"
        } else {
            return '';
        }

    }

    function generateColumnGrouping(sources, tableFields) {
        const columnGrouping = [];
        sources.forEach((source) => {
            const tableHeadings = tableFields.filter((header) => header.dataSource == source);
            columnGrouping.push({groupId: source, children: tableHeadings });
        });
        return columnGrouping;
    }

    const CustomGridToolbar = (props) => {
        const apiRef = useGridApiContext();
        return (
            <GridToolbarContainer style={{height:35 ,justifyContent: "space-between", alignItems:'end',backgroundColor:'black' }}>
                <h6 style={{color:'white'}}>Risk Analysis Results using the {selectedClimateScenario.join()} Climate Scenario ({selectedTimeframe.join()})</h6>
                <div>
                    <GridToolbarFilterButton style={{ color: "#FFF"}} />
                    <GridToolbarExport style={{ color: "#FFF"}} printOptions={{ disableToolbarButton: true }} csvOptions={{fileName: `${selectedAssetLayers[0].title}_${selectedClimateScenario}_${selectedTimeframe}_Summary`}}/>
                    {/* <Button sx={{ color: "#fff"}} variant="text" startIcon={<PictureAsPdfIcon />} onClick={() => generateSummaryReport(apiRef, props.analysisData, clientConfig, allAnalysisRiskData)}>Generate Summary Report</Button> */}
                </div>
            </GridToolbarContainer>
        )
    }

    const AnalysisResultsTable = () => {


        function handleTableTabChange(newTabEvent, newValue) {
            updateCurrentAnalysisTab(newValue);
        }

        return (
            <div>
                <div style={{justifyContent: "flex-end", display: "flex"}}>
                    <Fab onClick={closeAnalysisResultsTab} variant="extended" size="small" sx={[mapAnalysisToggleButton, {marginBottom: '-10px', padding: '10px'} ]}>
                        Close
                    </Fab>
                </div>
                <TabContext value={currentAnalysisTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTableTabChange} aria-label="assetsTab" >
                            {analysisResults.map((result, index) => {
                                return (
                                    <Tab label={result.assetTitle} sx={{ backgroundColor: "#212121", color: "#fff"}} value={`${index}`} key={index} />
                                )
                            })}
                        </TabList>
                    </Box>

                    {analysisResults.map((result, index) => {
                        const columns = parseAssetGridHeader(result.riskData, result.definition, result.assetLayerId).map((col) => {
                            if (typeof result.riskData[0][col.field] === 'number') {
                                const defaultFilterOperators = getGridStringOperators().map((operator) => ({ ...operator }));
                                const numericOperators = getGridNumericOperators().filter(
                                    (operator) => operator.value === '>' || operator.value === '<',
                                );

                                return {
                                    ...col,
                                    filterOperators: [...defaultFilterOperators, ...numericOperators],
                                };
                            }
                            
                            return col;
                        });
                        return (
                            <TabPanel value={`${index}`} sx={tab_panel_container} key={index+1}>
                                <Paper sx={riskTableStyles}>
                                    <DataGrid
                                        components={{ Toolbar: CustomGridToolbar }}
                                        componentsProps={{ toolbar: { analysisData: result } }}
                                        getRowId={(row) => row.id + result.assetLayerId}
                                        rowHeight={35}
                                        headerHeight={55}
                                        rows={result.riskData}
                                        onRowClick={(gridRow) => highlightAssetOnMap(gridRow.row, result.definition, result.assetLayerId)}
                                        columns={columns}
                                        columnGroupingModel={generateColumnGrouping(dataSources, sourceChildren)}
                                        getCellClassName={getRiskCellClass}
                                        pageSize={100}
                                        rowsPerPageOptions={[100]} 
                                        checkboxSelection={false}
                                        disableSelectionOnClick={false}
                                        showCellRightBorder
                                        disableColumnSelector
                                        disableColumnMenu
                                        editMode='row'
                                        experimentalFeatures={{ newEditingApi: true, columnGrouping:true }}
                                        sx={data_grid_styles}
                                    />
                                </Paper>
                                
                            </TabPanel>
                        )
                    })}

                </TabContext>
                
            </div>
        )
    }

    const isLayerActive = (layer) => {

        let currentMapLayers = [...mapLayers];
        let isOnMap = currentMapLayers.find((currentLayer) => { return currentLayer.options.id == layer.id; });
        if (layer.hasOwnProperty('data_reference')) { // checking for asset layer
            isOnMap = currentMapLayers.find((currentLayer) => { return currentLayer.options.id == layer.data_reference; })
        }

        if (isOnMap) {
            return true;
        } else {
            return false;
        }

    }

    useEffect(() => {
        loadInitialData();
    }, []);

    function highlightAssetOnMap(asset, definition, assetLayerId) {

        const currentMapLayers = mapCompRef.current.mapRef()._layers;
        const layerKeys = Object.keys(currentMapLayers);
        let assetLayerKey = null;

        layerKeys.forEach((layerKey) => {
            if (currentMapLayers[layerKey].hasOwnProperty('feature')) {

                const isRelevantAsset = currentMapLayers[layerKey].feature.properties.id == asset.id && currentMapLayers[layerKey].feature.asset_layer_id == assetLayerId ? true : false;

                if (isRelevantAsset) {
                    assetLayerKey = layerKey;
                }

                if (currentMapLayers[layerKey].options.radius == 15) { // reseting any previously highlighted asset 
                    if (!isRelevantAsset) { // checking that this asset isn't already highlighted and this is the same asset clicked
                        currentMapLayers[layerKey].setRadius(6); 
                    }
                }
                
            }

        });

        if (assetLayerKey) {

            const assetAlreadyHighlighted = currentMapLayers[assetLayerKey].options.radius == 15 ? true : false;

            if (!assetAlreadyHighlighted) {
                currentMapLayers[assetLayerKey].setRadius(15);
                const popupContent = getAssetTooltipContent(asset, definition, assetLayerId);

                L.popup()
                .setLatLng([asset.latitude, asset.longitude])
                .setContent(popupContent.join("<br />"))
                .on('remove', function() {
                    currentMapLayers[assetLayerKey].setRadius(6);
                })
                .openOn(mapCompRef.current.mapRef());

            }

        }
    }

    function getAssetTooltipContent(assetMetadata, assetDefinition, assetLayerId, riskData=null, currentRiskFilter=null) {
        if (assetMetadata) {
            
            const sortedMetadataKeys = assetDefinition.length > 0 ? sortAssetMetadataKeys(assetDefinition) : Object.keys(assetMetadata);
            const tooltipKeys = sortedMetadataKeys.filter((key) => { // getting asset properties that we want to display on tooltip 
                const definitionIndex = assetDefinition.findIndex((def) => def.column_name == key);
                const definitionInfo = assetDefinition[definitionIndex];
                if (definitionInfo.show_tooltip) {
                    return true;
                }
                return false;
            });
            const assetLayerIndex = assetLayers.findIndex((assetType) => assetType.id == assetLayerId);
            const assetTypeName = assetLayerIndex != -1 ? assetLayers[assetLayerIndex].title : '';

            const formatedProperties = ['<div style="text-align: center; background: #dbd9d9; min-width: 200px;"> <b>' + assetTypeName + '</b> </div>'];
            for (const keyIndex in tooltipKeys) {
                const key = tooltipKeys[keyIndex];
                const definitionInfoIndex = assetDefinition.length > 0 ? assetDefinition.findIndex((def) => def.column_name == key) : -1;
                const formatedMetadataValue = assetMetadata[key] == null ? 'N/A' : assetMetadata[key];
                
                // creating a line break for strings that are greater than 30 characters
                const wrappedText = String(formatedMetadataValue).length > 30 ? String(formatedMetadataValue).slice(0, 31) + '<br /> ' + String(formatedMetadataValue).slice(31) : formatedMetadataValue;
                if (definitionInfoIndex != -1) {
                    const camelCaseColumnAlias = camelCaseToSentenceCase(assetDefinition[definitionInfoIndex].column_alias);
                    formatedProperties.push("<b>" + camelCaseColumnAlias + "</b>" + ": " + wrappedText);
                } else {
                    const camelCaseFormat = camelCaseToSentenceCase(key);
                    const formatedKey = camelCaseFormat.replaceAll('_', ' ');
                    formatedProperties.push("<b>" + formatedKey + "</b>" + ": " + wrappedText);
                }
            }

            // checking to see if there is risk data for that asset
            const asset_risk_data = riskData != null ?
                        riskData.filter((risk_data) => risk_data.asset_id == assetMetadata.id && risk_data.asset_layer_id == assetLayerId) :
                        allAnalysisRiskData.filter((risk_data) => risk_data.asset_id == assetMetadata.id && risk_data.asset_layer_id == assetLayerId);
            
            if (asset_risk_data.length > 0) { // applying color according to risk analysis if there was one previously done
                formatedProperties.push('<br /><div style="text-align: center; background: #dbd9d9;"> <b>Risk Summary:</b> </div>');
                const currentAnalysisFilter = [...analysisFilter];
                const revelantAnalysisFilterIndex = currentAnalysisFilter.findIndex((filter) => filter.asset_layer_id == assetLayerId);
                const revelantAnalysisFilter = revelantAnalysisFilterIndex == -1 ? 'max_risk' : currentAnalysisFilter[revelantAnalysisFilterIndex].filter;

                const riskFilter = currentRiskFilter != null ? currentRiskFilter : revelantAnalysisFilter;

                const maxRisks = asset_risk_data.map(riskInfo => riskInfo.max_risk ? parseFloat(riskInfo.max_risk) : 1);
                const maxRisk = Math.max(...maxRisks);
                formatedProperties.push("<b>" + "Max Risk" + "</b>" + ': ' + maxRisk);

                for (const riskIndex in asset_risk_data) {
                    const riskInfo = asset_risk_data[riskIndex];
                    const dataset_name = riskInfo.layerTitle;
                    const max_risk = riskInfo.max_risk;
                    if(riskInfo.parameter_value){
                        if (riskFilter != 'max_risk') {
                            if (riskFilter == dataset_name) {
                                formatedProperties.push("<b>" + dataset_name + "</b>" + ': ' + max_risk);
                            }
                        } else {
                            formatedProperties.push("<b>" + dataset_name + "</b>" + ': ' + max_risk);
                        }
                    }
                }
            }

            return formatedProperties;
        } else {
            return '';
        }

    }

    function displayAssetTooltip(mapRef, assetMetadata, layer, riskData=null, currentRiskFilter=null) {
        
        const toolTipContent = getAssetTooltipContent(assetMetadata, layer.definition, layer.id, riskData, currentRiskFilter);

        mapRef.bindTooltip(toolTipContent.join("<br />"));
        mapRef.on('click', function(e) {
            openDetailedRiskModal(assetMetadata, layer.definition, layer.id);
        });
    }

    function camelCaseToSentenceCase(s) {
        if (s == null) {
          return;
        }
        const finalResult = s.charAt(0).toUpperCase() + s.slice(1);
        return finalResult;
    }

    function sortTreeInAscendingOrder(tree) {
        const sortedTree = JSON.parse(JSON.stringify(tree));
        const treeHierachy = [sortedTree];

        while (treeHierachy.length > 0) {
            const node = treeHierachy.shift();
            if (!node.hasOwnProperty('children')) {
                continue;
            }
            if (node.children.length > 0) {
                const sortedChildren = node.children.sort((child1, child2) => (child1.name.trim() > child2.name.trim()) ? 1 : (child1.name.trim() < child2.name.trim()) ? -1 : 0);
                node.children = sortedChildren;
                node.children.forEach((child) => {
                    treeHierachy.push(child);
                });
            }

        }
        return sortedTree;
    }

    async function loadInitialData() {
        try {
            await fetchClientConfig();
            await loadAssetLayers();
            await loadClimateLayers();
            updateLoading(false);
            updateLoadingDescription('Loading..');
        } catch (e) {
            updateLoading(false);
            console.log(e);
        }
    }

    async function loadClimateLayers() {

        updateLoadingDescription('Fetching Climate Layers...');
        const user = await authService.getUser();
        const client_id = user.profile.hasOwnProperty('awxClientId') ? parseInt(user.profile.awxClientId) : parseInt(user.profile.clientId);
        const [request, options] = await getClimateRegistry(client_id);
        const response = await fetch(request, options);
        if (response.status === 200) {
            const json = await response.json();
            updateClimateRegistry(json);
        } else {
            //handle error here
            console.log(response.status);
        }
    }

    async function fetchClientConfig() {
        updateLoadingDescription('Fetching Client Configuration...');
        const user = await authService.getUser();
        const client_id = user.profile.hasOwnProperty('awxClientId') ? parseInt(user.profile.awxClientId) : parseInt(user.profile.clientId);
        const [request, options] = await getClientConfig(client_id);
        const response = await fetch(request, options);
        if (response.status == 200) {
            const json = await response.json();
            if (json.length > 0) {
                const config = JSON.parse(json[0].config);
                if (config.hasOwnProperty('map')) { 
                    //apply map preferences
                    mapCompRef.current.mapRef().setView(config.map.center, config.map.zoom);
                    mapCompRef.current.mapRef().options.maxZoom = 18; //setting max zoom
                }
                updateClientConfig(config);
            }
            
        } else {
            console.log(response);
        }
    }

    async function loadAssetLayers() {

        updateLoadingDescription('Fetching Asset Layers...');
        const user = await authService.getUser();
        const client_id = user.profile.hasOwnProperty('awxClientId') ? parseInt(user.profile.awxClientId) : parseInt(user.profile.clientId);
        const [request, options] = await getUserAssets(client_id);

        const response = await fetch(request, options);
        if (response.status === 200) {
          const json = await response.json();
          updateAssetLayers(json);

        } else {
            //handle error here
            console.log(response.status);
        }

    }

    function formatRiskData(riskData, assets) {
        const formatedRiskData = [];

        for (let i = 0; i < assets.length; i++) {
            const riskDataForAsset = riskData.filter((data) => data.asset_id == assets[i].id);

            if (riskDataForAsset.length > 0) {
                const riskObj = JSON.parse(JSON.stringify(riskDataForAsset[0])); // using only first risk data object as template
                const max_risk = Math.max(...riskDataForAsset.map(item => item.risk)); // getting max risk value for all asset risk data combinations
                riskObj['max_risk'] = max_risk;
                riskObj['all_risk_combinations'] = riskDataForAsset;
                formatedRiskData.push(riskObj);
            }
        }

        return formatedRiskData;
    }

    async function viewAnalysisResults(selectedAssetLayers, selectedClimateLayers) {

        updateAnalysisFilter([]); // resetting analysis results filter
        updateLoading(true);
        updateLoadingDescription('Fetching Risk Scores Based on Analysis....');

        const all_risk_data = [];
        const all_risk_data_raw = [];

        for(let i = 0; i < selectedAssetLayers.length; i++) {
            const assetLayerId = selectedAssetLayers[i].id;
            const user = await authService.getUser();
            const client_id = user.profile.hasOwnProperty('awxClientId') ? parseInt(user.profile.awxClientId) : parseInt(user.profile.clientId);
            const assets = JSON.parse(JSON.stringify(selectedAssetLayers[i].metaData));
            const assetDefinition = selectedAssetLayers[i].definition;
            const sourceTableFields = [];

            // toggling asset layer off
            if (isLayerActive(selectedAssetLayers[i])) {
                updateAssetsOnMap(selectedAssetLayers[i]);
            }

            const timeframes = [];
            const scenarios = [];
            const sources = [];

            for (let j = 0; j < selectedClimateLayers.length; j++) {
                const dataParameter = selectedClimateLayers[j].data_parameter;
                const source = selectedClimateLayers[j].source;
                const timeframe = selectedClimateLayers[j].timeframe;
                const scenario = selectedClimateLayers[j].scenario;

                timeframes.push(timeframe);
                scenarios.push(scenario);
                sources.push(source);

                const [request, options] = await getRiskScores(client_id, assetLayerId, source, timeframe, scenario, dataParameter);
                const response = await fetch(request, options);
                if (response.status === 200) {
                    let parameter_risk_data = await response.json();

                    if (selectedAssetLayers[i].risk_type == 2) {
                        parameter_risk_data = formatRiskData(parameter_risk_data, assets); // this is needed to accomodate complex asset risk data 
                    }


                    // Adding asset layer id to risk data to identify asset risk data per asset type and rounding max risk value to nearest integer
                    parameter_risk_data.forEach((risk_data) => { 
                        risk_data['asset_layer_id'] = assetLayerId;
                        risk_data['max_risk'] = Math.round(risk_data['max_risk']);
                        risk_data['risk_type'] = selectedAssetLayers[i].risk_type;
                    });
                    let title
                    if (selectedClimateLayers[j].title.includes('(')){  // creating column name to hold maximum risk for that climate layer (split is responsible for separating date which may be in title)
                        title = selectedClimateLayers[j].title.split(' (')
                    }else {
                        title = selectedClimateLayers[j].title.split(/(\d+)/)
                    }

                    let dataset_name = title[0];
                    sourceTableFields.push({field: dataset_name, dataSource: source });

                    all_risk_data_raw.push(...parameter_risk_data);
                    parameter_risk_data.forEach(risk_data => { // map each asset to their max risk value for that parameter for that asset layer
                        const risk_data_asset_id = risk_data.asset_id; 
                        const corresponding_asset_index = assets.findIndex(asset => asset.id == risk_data_asset_id); 
                        risk_data['layerTitle'] = dataset_name;

                        if (corresponding_asset_index != -1) {
                            assets[corresponding_asset_index][dataset_name] = risk_data.max_risk; // adding column in table to represent maximum risk for that climate layer
                        }
                    });

                } else {
                      //handle error here
                      console.log(response.status);
                }
            }

            // Getting and storing unique climate scenario, timeframes and source for analysis results table column grouping and for title
            const uniqueScenarios = scenarios.filter((item, index) => scenarios.indexOf(item) === index);
            const uniqueTimeframes = timeframes.filter((item, index) => timeframes.indexOf(item) === index);
            const uniqueSources = sources.filter((item, index) => sources.indexOf(item) === index);

            updateSelectedAssets(selectedAssetLayers);
            updateSourceChildren(sourceTableFields);
            updateSelectedClimateScenario(uniqueScenarios);
            updateSelectedTimeframe(uniqueTimeframes);
            updateDataSource(uniqueSources);

            // setting up column to hold maximum (max risk values) for all selected climate layers
            assets.forEach((asset) => {
                const maxRisk = Math.max(...sourceTableFields.map((layer) => asset[layer.field] ? parseFloat(asset[layer.field]) : 1)); // getting maximum risk for all climate layers
                asset['max_risk'] = maxRisk;
            });

            // reloading assets back onto map
            await updateRiskDataForAssetsOnMap(selectedAssetLayers[i], all_risk_data_raw);

            const assetInfo = { 'riskData': assets, 'definition': assetDefinition, 'climateLayers': selectedClimateLayers, 'assetTitle': selectedAssetLayers[i].title, 'assetLayerId': assetLayerId, 'risk_type': selectedAssetLayers[i].risk_type };
            all_risk_data.push(assetInfo);

        }

        updateAnalysisResults(all_risk_data);
        closeAnalysisPanel();
        openAnalysisResultsTab();
        updateLoading(false);
        updateLoadingDescription('Loading....');
    }

    async function updateRiskDataForAssetsOnMap(assetLayerInfo, riskData) {

        updateAnalysisRiskData(riskData);
        refreshAssetsOnMap([...assetsOnMap, assetLayerInfo]);
        const mapLayer = await constructAssetLayer(assetLayerInfo, riskData, 'max_risk');

        updateMapLayers((layers) => layers.concat(mapLayer));
        mapCompRef.current.mapRef().addLayer(mapLayer);
        zoomToAssetsLocation(assetLayerInfo.metaData, assetLayerInfo.id);

    }

    function sortAssetMetadataKeys(definition) { // goes through asset definition and sorts columns according to their priority in definition

        const sortedDefinition = definition.sort((a, b) => (a.column_priority > b.column_priority) ? 1 : -1);
        const sortedMetadataKeys = [];
        sortedDefinition.forEach((assetProperty) => {
            if (!assetProperty.is_hidden) {
                sortedMetadataKeys.push(assetProperty.column_name);
            }
        });

        return sortedMetadataKeys;
    }

    async function updateAssetsOnMap(layer) {

        let currentMapLayers = [...mapLayers];
        const layerIndex = currentMapLayers.findIndex(assetLayer => assetLayer.options.id == layer.data_reference);
        if (layerIndex == -1) {
            // fetching assets for asset layer
            try {
                updateLoading(true);
                updateLoadingDescription('Fetching Assets For Layer....');
                const user = await authService.getUser();
                const client_id = user.profile.hasOwnProperty('awxClientId') ? parseInt(user.profile.awxClientId) : parseInt(user.profile.clientId);
                const [request, options] = await getAssetLayerData(client_id, layer.id);
                const response = await fetch(request, options);
                if (response.status === 200) {
                    const json = await response.json();
                    const layerCopy = JSON.parse(JSON.stringify(layer));
                    layerCopy['metaData'] = json[0];
                    layerCopy['definition'] = json[1];

                    // finding asset shape to use (not currently being used)
                    const assetShapesNotOnMap = assetRandomShapes.filter((shape) => assetLegend.findIndex((asset) => asset.shape == shape) == -1); // basically getting a shape not already on map
                    layerCopy['markerShape'] = assetShapesNotOnMap[0];

                    zoomToAssetsLocation(json[0], layer.id);
                    updateLayers(layerCopy);
                    updateAnalysisFilter([]); // resetting analysis results filter
                    refreshAssetsOnMap([...assetsOnMap, layerCopy]);

                    // updating assets legend
                    updateAssetLegend([...assetLegend, {'id': layer.data_reference, 'name': layer.title, 'shape': assetShapesNotOnMap[0]}]);
                } else {
                    //handle error here
                }

                updateLoading(false);
                updateLoadingDescription('Loading....');
                toggleAssetLayerMenu();

            } catch(error) {
                console.log(error);
                updateLoading(false);
                updateLoadingDescription('Loading....');
            }
        } else {
            const arrCopy = [...assetsOnMap];
            arrCopy.splice(layerIndex, 1);
            updateLayers(layer);
            refreshAssetsOnMap(arrCopy);
            // updating legend for asset
            const currentLegend = [...assetLegend];
            const assetLegendIndex = currentLegend.findIndex(legend => legend.id == layer.data_reference);
            currentLegend.splice(assetLegendIndex, 1);
            updateAssetLegend(currentLegend);
        }

    }

    function generateAssetMarker(latlng, defaultMarkerOptions, assetShape) {
        // in the future we want to implement the ability to have different asset shapes for each asset type
        return L.circleMarker(latlng, defaultMarkerOptions);

    }

    function zoomToAssetsLocation(assets, layerId) {

        const assetLayerZoomMap = { // mapping each asset layer to how much we want to zoom on map.. we have this hardcoded here for now since criva is still small
            '1': 6,
            '2': 9,
            '3': 9,
            '4': 6,
            '5': 7
        };

        const layerZoomLevel = assetLayerZoomMap.hasOwnProperty(layerId) ? assetLayerZoomMap[layerId] : 6; 

        let latitudeSum = 0;
        let longitudeSum = 0;

        assets.forEach((asset) => {
            latitudeSum += asset.latitude;
            longitudeSum += asset.longitude;
        });

        const centerLatitude = latitudeSum / assets.length;
        const centerLongitude = longitudeSum / assets.length;

        mapCompRef.current.mapRef().setView([centerLatitude, centerLongitude], layerZoomLevel);
    }

    const updateLayersOnMap = async (currentClimateLayerId) => {

        const climateRegistryLayer = climateRegistry.filter((layer) => layer.id == currentClimateLayerId)[0];
        let currentLayerOnMap = climateLayerOnMap == null ? null : JSON.parse(JSON.stringify(climateLayerOnMap));

        if (currentLayerOnMap != null) { // means there is a layer on the map we need to remove
            if (currentLayerOnMap.id == currentClimateLayerId) { // layer is already on map
                return;
            }
            updateLayers({id: currentLayerOnMap.id});
            currentLayerOnMap = null; //reseting variable
        }

        if (currentClimateLayerId != 'none') { //adding new layer to map

            // fetching display info for climate layer
            if (!isLoading) {
                updateLoading(true);
                updateLoadingDescription('Fetching Layer Display Info....');
            }
            const [request, options] = await getClimateLayerDisplayInfo(climateRegistryLayer.display_type, climateRegistryLayer.display_id);
            const response = await fetch(request, options);
            if (response.status === 200) {
                const display = await response.json();
                currentLayerOnMap = climateRegistryLayer;
                if (climateRegistryLayer.display_type == 'wms') { // only type we are supporting for now
                    const wmsInfo = {
                        id: climateRegistryLayer.id,
                        name: climateRegistryLayer.title,
                        url: display[0].source + '?',
                        layers: display[0].name,
                        styles: display[0].styles ? display[0].styles : '',
                        transparent: display[0].transparent ? true : false,
                        type: "wms",
                        format: display[0].format
                    }
                    updateLayers(wmsInfo);
                }
            }

        }

        updateMapClimateLayers(currentLayerOnMap);
        updateLoading(false);
        updateLoadingDescription('Loading....');

    }

    async function constructAssetLayer(assetLayer, riskData=null, currentRiskFilter=null) {

        try {
            // we can probably proxy this through the back end in the future
            const convertedToGeoJSON = convertCSVToGeoJson(assetLayer.metaData, assetLayer.id);
            // L.canvas is needed for the renderer because that is the only way html2canvas will pick those markers when we generate the summary report
            const defaultMarkerOptions = { fillColor: "rgba(168, 208, 141, 1)", color: "#000", radius: 6, weight: 2, opacity: 1, fillOpacity: 0.8, renderer: L.canvas() };

            const geoJSON = L.geoJSON(convertedToGeoJSON, {
                id: assetLayer.data_reference,
                pointToLayer: (geoJsonPoint, latlng) => {

                    const asset_risk_data = riskData != null ?
                        riskData.filter((risk_data) => risk_data.asset_id == geoJsonPoint.properties.id && risk_data.asset_layer_id == assetLayer.id) :
                        allAnalysisRiskData.filter((risk_data) => risk_data.asset_id == geoJsonPoint.properties.id && risk_data.asset_layer_id == assetLayer.id);

                    if (asset_risk_data.length > 0) { // applying color according to max risk 
                        const maxRisk = Math.max(...asset_risk_data.map(data => parseFloat(data.max_risk)));
                        const riskColor = getRiskColor(maxRisk);
                        defaultMarkerOptions['fillColor'] = riskColor;
                    }

                    const assetMarker = generateAssetMarker(latlng, defaultMarkerOptions, assetLayer.markerShape);
                    return assetMarker;
                },
                onEachFeature: function popUp(f, l) {
                    displayAssetTooltip(l, f.properties, assetLayer, riskData, currentRiskFilter);
                }
            });

            return geoJSON;

        } catch(error) {
            console.log(error);
            return null;
        }


    }

    function convertCSVToGeoJson(parsedCsv, assetLayerId) {
        const features = [];
        const possibleLatitudeNames = ["latitude", "lat"];
        const possibleLongitudeNames = ["longitude", "lon"];

        for (const rowIndex in parsedCsv) {
          const row = parsedCsv[rowIndex];
          const feature = {"type": "Feature", "properties": {}, "geometry": { "type": "Point", "coordinates": [0,0] }};
          for (const column in row) {
            feature['properties'][column] = row[column];
            if (possibleLatitudeNames.includes(column.toLowerCase())) {
              feature["geometry"]["coordinates"][1] = parseFloat(row[column]);
            } else if (possibleLongitudeNames.includes(column.toLowerCase())) {
              feature["geometry"]["coordinates"][0] = parseFloat(row[column]);
            }
          }
          feature['asset_layer_id'] = assetLayerId;
          features.push(feature);
        }
        return features;
    }

    function getRiskColor(risk) {

        if (risk >= 0 && risk < 3) {
            return "rgba(168, 208, 141, 1)";
        } else if (risk >= 3 && risk < 5) {
            return "rgba(0, 176, 240, 1)";
        } else if (risk >= 5 && risk < 6) { // want to cater for decimal values like 5.5, 5.6 etc
            return "rgba(255, 230, 153, 1)";
        } else if (risk >=6 && risk < 10) {
            return "rgba(255, 255, 0, 1)";
        } else if (risk >= 10 && risk < 17) {
            return "rgba(255, 119, 0, 1)";
        } else if (risk >= 20) {
            return "rgba(255, 0, 0, 1)";
        } else {
            return "rgba(168, 208, 141, 1)";
        }
    }

    async function constructWMSLayer(layerInfo) {

        const wmsPane = mapCompRef.current.mapRef().createPane('wmsPane');
        wmsPane.style.zIndex = 250; // we need this so wms layers will appear on top when toggling base maps

        const [backendWMSProxy, auth] = await getGeoserverProxy();

        L.TileLayer.WMSExtented = L.TileLayer.WMS.extend({ 
            onAdd: async function (map) {
              // Triggered when the layer is added to a map.
              L.TileLayer.WMS.prototype.onAdd.call(this, map);
              mapCompRef.current.mapRef().on('click', this.getFeatureInfo, this);
            },
            onRemove: function (map) {
              // triggered when the layer is removed from a map.
              L.TileLayer.WMS.prototype.onRemove.call(this, map);
              mapCompRef.current.mapRef().off('click', this.getFeatureInfo, this);
            },
            createTile: function (coords, done) { // needed this to able to pass authorization headers to access backend api
                const tile = document.createElement('img');
            
                // Set up event handlers for the image element
                tile.onload = L.bind(this._tileOnLoad, this, done, tile);
                tile.onerror = L.bind(this._tileOnError, this, done, tile);
            
                // Set CORS
                if (this.options.crossOrigin) {
                    tile.crossOrigin = '';
                }
            
                tile.alt = '';
                tile.setAttribute('role', 'presentation');
            
                // Get the URL with the appropriate parameters for the WMS request
                const url = this.getTileUrl(coords);
            
                // Create a new XMLHttpRequest to include the headers
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.setRequestHeader('Authorization', `Bearer ${auth}`);
                xhr.setRequestHeader('Accept', 'image/png');
            
                xhr.responseType = 'blob';
            
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const objectURL = URL.createObjectURL(xhr.response);
                        tile.src = objectURL;
                    } else {
                        done(new Error(`Tile loading error: ${xhr.statusText}`), null);
                    }
                };
            
                xhr.onerror = function () {
                    done(new Error('Tile loading error'), null);
                };
            
                xhr.send();
            
                return tile;
            },            
            getFeatureInfo: async function (evt) {
                const requestUrl = this.getFeatureInfoUrl(evt.latlng);
                const response = await fetch(requestUrl);

                if (response.status == 200) {
                    const resp_content = await response.json();
                    if (resp_content.features.length > 0) {
                        const allProperties = [];
                        let featureCount = 0;
                        for (const feature in resp_content.features) {
                            const current_feature = resp_content.features[feature];
                            const properties = Object.keys(current_feature.properties)
                            featureCount++;
                            for (let i = 0; i < properties.length; i++) {
                                const key = properties[i];
                                if (i < 1) { // mainly used if there are multiple features and we want to beable to separate the info on the popup
                                    allProperties.push("Feature " + featureCount);
                                }

                                const camelCaseFormat = camelCaseToSentenceCase(key);
                                const formatedKey = camelCaseFormat.replaceAll('_', ' ');
                                allProperties.push(formatedKey + ": " + current_feature.properties[key])

                            }
                        }
                        L.popup({ maxWidth: 800 })
                        .setLatLng(evt.latlng)
                        .setContent(allProperties.join("<br />"))
                        .openOn(this._map);
                    }
                }

            },
            getFeatureInfoUrl: function (latlng) {
                // Construct a GetFeatureInfo request URL given a point
                var point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
                size = this._map.getSize(),
                params = {
                  request: 'GetFeatureInfo',
                  service: 'WMS',
                  srs: 'EPSG:4326',
                  styles: this.wmsParams.styles,
                  transparent: this.wmsParams.transparent,
                  version: this.wmsParams.version,
                  format: this.wmsParams.format,
                  bbox: this._map.getBounds().toBBoxString(),
                  height: size.y,
                  width: size.x,
                  layers: this.wmsParams.layers,
                  query_layers: this.wmsParams.layers,
                  info_format: 'application/json'
                };
                params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
                params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;
                return this._url + L.Util.getParamString(params, this._url, true);
            }

        });

        const wmsLayer = new L.TileLayer.WMSExtented(backendWMSProxy, {
            id: layerInfo.id,
            baseUrl: layerInfo.url,
            layers: layerInfo.layers,
            styles: layerInfo.styles,
            transparent: layerInfo.transparent,
            format: layerInfo.format,
            pane: wmsPane,
            opacity: 0.8
        });

        return wmsLayer;
    }

    function constructShapeFileLayer(layerInfo) {

        try {
            const defaultMarkerOptions = { radius: 6, fillColor: "rgba(45, 189, 182, 1)", color: "#000", weight: 2, opacity: 1, fillOpacity: 0.8 };

            const shape = L.geoJSON(
                { features: [] },
                {
                id: layerInfo.id,
                pointToLayer: (feature, latlng) => {
                    return L.circleMarker(latlng, defaultMarkerOptions)
                },
                onEachFeature: function popUp(f, l) {
                    var out = [];
                    if (f.properties) {
                    for (var key in f.properties) {
                        out.push(key + ": " + f.properties[key]);
                    }
                    l.bindPopup(out.join("<br />"));
                    }
                }
                }
            );

            shp(layerInfo.url).then(function (data) {
                shape.addData(data);
            });
            return shape;

        } catch(error) {
            console.log(error);
            return null;
        }
    }

    async function constructGeoJsonLayer(layerInfo) {
        const defaultMarkerOptions = { radius: 6, fillColor: "rgba(219, 123, 13, 1)", color: "#000", weight: 2, opacity: 1, fillOpacity: 0.8 };

        try {
            // we can probably proxy this through the back end in the future
            const response = await fetch(layerInfo.url);
            const data = await response.json();

            const geoJSON = L.geoJSON(data, {
                id: layerInfo.id,
                pointToLayer: (geoJsonPoint, latlng) => {
                return L.circleMarker(latlng, defaultMarkerOptions);
                },
                onEachFeature: function popUp(f, l) {
                    var out = [];
                    if (f.properties) {
                        for (var key in f.properties) {
                            out.push(key + ": " + f.properties[key]);
                        }
                        l.bindPopup(out.join("<br />"));
                    }
                }
            });

            return geoJSON;

        } catch(error) {
            console.log(error);
            return null;
        }
    }

    async function updateLayers(layer) {

        let currentMapLayers = [...mapLayers];

        // check if map layer is already on the map
        let isOnMap = currentMapLayers.find((currentLayer) => { return currentLayer.options.id == layer.id; });
        if (layer.hasOwnProperty('data_reference')) {
            isOnMap = currentMapLayers.find((currentLayer) => { return currentLayer.options.id == layer.data_reference; })
        }

        if (isOnMap) { // removing layer from map
            const mapLayer = layer.hasOwnProperty('data_reference') ?
                currentMapLayers.find(x => x.options.id === layer.data_reference) :
                currentMapLayers.find(x => x.options.id === layer.id);
            if (layer.hasOwnProperty('data_reference')) {
                updateMapLayers((mapLayers) => mapLayers.filter((mLayer) => { return mLayer.options.id !== layer.data_reference }));
            } else {
                updateMapLayers((mapLayers) => mapLayers.filter((mLayer) => { return mLayer.options.id !== layer.id }));
            }
            mapCompRef.current.mapRef().removeLayer(mapLayer);

        } else { // adding layer to map
            let mapLayer = null;
            if (layer.type == "wms") {
                mapLayer = await constructWMSLayer(layer);
            } else if (layer.type == "shapefile") {
                mapLayer = constructShapeFileLayer(layer);
            } else if (layer.type == "geojson") {
                mapLayer = await constructGeoJsonLayer(layer);
            } else if (layer.hasOwnProperty('data_reference')) { // For displaying assets on map
                mapLayer = await constructAssetLayer(layer);
            }
            updateMapLayers((layers) => layers.concat(mapLayer));
            mapCompRef.current.mapRef().addLayer(mapLayer);
        }
    }

    function updateClimateLayerOpacity(opacity) {

        let currentMapLayers = [...mapLayers];
        const mapLayer = currentMapLayers.find(x => x.options.id === climateLayerOnMap.id);
        const hasLayer = mapCompRef.current.mapRef()._layers.hasOwnProperty(mapLayer._leaflet_id);

        if (hasLayer) {
            mapCompRef.current.mapRef()._layers[mapLayer._leaflet_id].setOpacity(opacity);
        }
    }

    const AssetsLegend = () => { // too be implemented in the future
        return null;
        /*return (
            <div style={assetLegendContainer}>
                <div style={assetLegendItems}>
                    <div style={{ color: "#fff"}}>
                        <Typography variant='overline'>Assets</Typography>
                    </div>
                   
                    {assetLegend.map((asset) => {
                        return (
                            <div style={assetLegendRow}> 
                                <div class={ asset.shape == "circle" ? "asset-legend-circle" : asset.shape == "rectangle" ? "asset-legend-rectangle" : "asset-legend-triangle"}></div>
                                <Typography sx={assetLegend_row_subtitle} variant='body1'>{asset.name}</Typography>
                            </div>
                        )
                    })}
                </div>
            </div>
        ) */
    }

    return (

        <MapOuterDiv id="mapPage">

           <MapNav id="mapNavBar">
                <MapNavbarContainer>
                    <MapNavLogo to="/" >DecisionVue: Climate Impact</MapNavLogo>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: "50px", padding: '21px'}}>

                        <div style={menu_button_container}>
                            <Button style={{ color: "#fff" }} onClick={toggleClimateLayerMenu} endIcon={climateLayerMenuStatus ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}>
                                Climate Layers
                            </Button>
                            {climateRegistry.length > 0 ?
                                <ClimateLayerMenu 
                                    displayMenu={climateLayerMenuStatus} 
                                    climateLayers={climateRegistry} 
                                    currentTimeframe={menuClimateTimeframe} 
                                    currentScenario={menuClimateScenario} 
                                    currentClimateParameter={menuClimateParameter} 
                                    updateFilter={updateClimateLayerFilter}
                                /> : <></>
                            }

                        </div>
                        <div style={menu_button_container}>
                            <Button style={{ color: "#fff" }} onClick={toggleAssetLayerMenu} endIcon={assetLayerMenuStatus ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}>
                                Asset Layers
                            </Button>
                            <Paper sx={[custom_menu_dropdown, {display: assetLayerMenuStatus ? 'flex' : 'none'}]} id="assetLayersDropdown">
                                <FormGroup>
                                    {assetLayers.map((layer) => {
                                        return (
                                                <FormControlLabel control={<Checkbox checked={isLayerActive(layer)} size="small" onChange={() => updateAssetsOnMap(layer)} />} key={layer.id} label={<Typography variant="body2">{layer.title}</Typography>} />
                                        )
                                    })}
                                </FormGroup>
                            </Paper>
                        </div>
                        <Button style={{ color: "#fff" }} onClick={openAnalysisPanel} >Run Analysis</Button>
                        { analysisResults.length != 0  ?
                            <Button onClick={openAnalysisResultsTab} variant="extended" sx={mapAnalysisToggleButton}>
                                View Analysis Results
                            </Button>
                            :
                            null
                        }
                    </div>
                    <SettingsDropdown/>
                </MapNavbarContainer>
            </MapNav>

            <MapComponent ref={mapCompRef}/>

            <div style={{ position: 'absolute', bottom: 0, left:0, maxWidth: '100%', zIndex: '1299', padding: '5px 5px'}} id="analysisResultsTable">
                { showAnalysisResultsTab ? <AnalysisResultsTable /> : null}
            </div>

            { assetsOnMap.length > 0 ? <RiskLegend></RiskLegend> : null }

            { assetsOnMap.length > 0 ? <AssetsLegend /> : null}

            { climateLayerOnMap != null ? <ClimateLayerLegend onOpacityChange={updateClimateLayerOpacity} climateLayer={climateLayerOnMap}></ClimateLayerLegend> : null }
       
            <Modal open={detailedRiskModelOpen} onClose={closeDetailedRiskModal}>
                <DetailsModal asset={detailRiskModalAsset} riskData={allAnalysisRiskData} />
            </Modal>

            <Modal
                open={isLoading}
            >
                <Box sx={{ ...loaderStyle}}>
                    <CircularProgress />
                    <h2 style={loadingDescriptionStyle}>{loadingDescription}</h2>
                </Box>
            </Modal>

            <Modal
                open={analysisPanelOpen}
                onClose={handleBackdropClick}
            >
                <AnalysisPanel closeAnalysisPanel={closeAnalysisPanel} closePanel={viewAnalysisResults} assets={assetLayers} climateRegistry={climateRegistry}/>
            </Modal>
        </MapOuterDiv>
    )
}

export default MapPage;