import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { MapNavMenu } from '../Map/MapElements';
import NavDropdown from 'react-bootstrap/NavDropdown'
import authService from '../../utils/AuthenticationService';
import Button from '@mui/material/Button';
import userManual from '../../pdfs/Climate_Impacts_User_Manual.pdf'; 

export const SettingsDropdown = () => {

    const navigate = useNavigate()

    const [adminLink, setAdminLink] = useState(null)
    const [admin, setAdmin] = useState(null)

    async function getProfile(){
        let profile = await authService.getProfile()
        return profile
    }

    useEffect(() => {

        async function setValues() {
            let profile = await getProfile();
        
            setAdminLink(`https://auth.woodweather.net/t/${profile.organization_id}/Users/`);
            
            let roles = profile.hasOwnProperty('role') ? profile.role : [];
            let admin = false

            for (let i=0; i < roles.length; i++){
                if (roles[i].includes('Admin')){
                    admin = true
                    break
                }
            }

            setAdmin(admin)
        }

        setValues()
    },[]);

  return (
    <MapNavMenu>
        <Button style={{ color: "#fff", fontSize: 15.5, marginRight: '35px', marginBottom: '2px' }} onClick={() => window.open(userManual)}>
            User Manual
        </Button>

        <NavDropdown title="SETTINGS" id="settingsDropdown" style={{ marginRight: '35px', color:'white'}}>
            <NavDropdown.Item onClick={() => navigate('/profile')}>Profile</NavDropdown.Item>
            {admin == true && <NavDropdown.Item href={adminLink} target="_blank"> Admin</NavDropdown.Item>}
            <NavDropdown.Item onClick={() => authService.logout()}>Logout</NavDropdown.Item>
        </NavDropdown>
    </MapNavMenu>
  )
}
