import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import baseMapProvider from './baseMapProvider';
import "leaflet/dist/leaflet.css";
import { MapMainContainer } from './MapElements';

const MapBaseLayers = () => {
    const layers = baseMapProvider.map((layer, index) => {
       return (
            <LayersControl.BaseLayer checked={layer.default ? true : false} key={index} name={layer.name}>
                <TileLayer
                    attribution={layer.attribution}
                    url={layer.url}
                />
            </LayersControl.BaseLayer>
       )
    });

   return layers;
}

export const MapComponent = forwardRef((props, ref) => {
    const [map, setMap] = useState(null);
    const maxBounds = [[-90, -180], [90, 180]];

    useImperativeHandle(ref, () => ({
        mapRef() {
            return map;
        }
    }));

    return (
            <MapMainContainer
                center={[51.0, 19.0]}
                style={{ width: '100%', position: 'relative', display: 'flex'}}
                zoom={3}
                minZoom={3}
                maxZoom={15}
                maxBounds={maxBounds}
                ref={setMap}
                id="mapContainer"
            >

                <LayersControl position="topright">
                    <MapBaseLayers />
                </LayersControl>

            </MapMainContainer>
    )
})
